<template>
  <div class="auth">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-6">
          <div class="card shadow">
            <div class="card-body">
              <h4 class="card-title bordered">TRADESK</h4>
              <h5 class="mb-4 text-center text-secondary">
                Recover your account
              </h5>
              <template v-if="! email_sent">
                <form @submit.prevent="submit">
                  <div class="alert alert-danger" v-if="invalid_email">The email provided is invalid</div>
                  <div class="form-group">
                    <label for="email">Your Email</label>
                    <input
                      type="text"
                      placeholder="Email"
                      id="email"
                      class="form-control has-error"
                      v-model="user.email"
                      required
                    />
                  </div>
                  <div class="form-group text-right">
                    <button class="btn btn-theme">
                      <i class="lni-envelope mr-2"></i> Send Recovery Email
                    </button>
                  </div>
                </form>
                <p>
                  <router-link :to="{ name: 'register' }"
                    ><i class="lni-lock mr-3"></i> Don't have an account?
                    Register.</router-link
                  >
                </p>
                <p>
                  <router-link :to="{ name: 'login' }"
                    ><i class="lni-arrow-left mr-3"></i> Back to
                    login.</router-link
                  >
                </p>
              </template>
              <template v-if="email_sent">
                <p class="text-center">We have sent a recovery email to {{ user.email }}. Click on the link in your email to proceed.</p>
                <p class="text-center"><router-link :to="{ name: 'login' }" class="btn btn-theme text-white"><i class="lni-lock mr-2"></i> Go To Login</router-link></p>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: {
        email: "",
      },
      invalid_email: false,
      email_sent: false
    };
  },

  methods: {
    submit() {
      this.$loader.start();
      this.invalid_email = false;
      this.email_sent = false

      this.$axios
        .post("/api/v1/reset-account", this.user)
        .then(() => {
          this.$loader.stop();
          this.email_sent = true
        })
        .catch(() => {
          this.invalid_email = true;
          this.$loader.stop();
        });
    },
  },
};
</script>
